import React from 'react'

const SettingsPage = (props: any) => {
  return (
    <div>
      <h3>Settings</h3>
    </div>
  )
}

export default SettingsPage
